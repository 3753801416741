import { useSnackbar } from "notistack";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Button, MenuItem, Select, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useControllerClient } from "../hooks/useControllerClient";
import { useRegistrarClient } from "../hooks/useRegistrarClient";
import { useSearchContext } from "../store";
import {
  getAccount,
  getWeb3Instance,
  getChainId,
} from "../utils/MetamaskHelper";
import { toInteger } from "lodash";

const namehash = require("eth-ens-namehash");
const Web3 = require("web3");

const SetPrimaryStyle = styled(Box)`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 48px;
  width: 100%;
  max-width: 560px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  border-radius: 8px 0 0 8px;
  margin: 24px 0 16px 0;

  .MuiOutlinedInput-root {
    font-size: inherit;
    flex-grow: 1;
    background: linear-gradient(
      180deg,
      #ffffff 60%,
      rgba(255, 255, 255, 0) 130%
    );

    input {
      padding-left: 24px;
      padding-right: 24px;
    }

    fieldset {
      border: none;
    }
  }

  button {
    text-transform: uppercase;
    flex-shrink: 0;
    padding: 0 16px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    color: white;
    border-radius: 0 8px 8px 0;
    font-size: inherit;
  }
`;

const SetPrimary = () => {
  const { t } = useTranslation();
  const { account, action, current, connected } = useSearchContext();
  const [sns, setSNS] = useState("");
  const clientController = useControllerClient();
  const clientRegistrar = useRegistrarClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setSNS(e.target.value);
  };

  async function handleSetPrimmary() {
    if (!connected) {
      enqueueSnackbar(t("notConnected"), { variant: "error" });
      return;
    }
    var sns_namehash = namehash.hash(sns);
    const web3Instance = await getWeb3Instance();
    const account = await getAccount();
    const tx = await clientController.setPrimaryName(sns_namehash);
    const gasPrice = await web3Instance.eth.getGasPrice();
    const gasLimit = await web3Instance.eth.estimateGas({
      to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
      from: account,
      value: 0,
      data: tx.encodeABI(),
    });
    const transactionParameters = {
      to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
      from: account,
      value: Web3.utils.toHex(0),
      nonce: await toInteger(web3Instance.eth.getTransactionCount(account)),
      chainId: await getChainId(),
      gasPrice: Web3.utils.toHex(gasPrice),
      gasLimit: Web3.utils.toHex(gasLimit),
      data: tx.encodeABI(),
    };
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      })
      .then((res) => {
        const transactionReceiptAsync = function () {
          web3Instance.eth.getTransactionReceipt(res, (error, receipt) => {
            if (error) {
              console.log(error);
            } else if (receipt == null) {
              setTimeout(() => transactionReceiptAsync(), 500);
            } else {
              console.log(receipt);
              console.log(sns);
              enqueueSnackbar(t("setNameSuccess"), { variant: "success" });
              clientController
                .getPrimaryName(account)
                .call()
                .then((primaryName) => action.setPrimaryName(primaryName));
              const domainName = sns.replace(".six", "");
              const tokenId = Web3.utils.sha3(domainName);
              clientRegistrar
                .tokenURI(tokenId)
                .call()
                .then((baseURI) => action.setPrimaryBaseUri(baseURI));
            }
          });
        };
        transactionReceiptAsync();
      })
      .catch((err) => {
        if (!err.message.includes("rejected")) {
          enqueueSnackbar(t("setNameError"), { variant: "error" });
          console.error(err);
        }
      });
  }

  useEffect(() => {
    async function load() {
      if (clientController) {
        const key = await getAccount();
        const primaryName = await clientController.getPrimaryName(key).call();
        if (primaryName == null) {
          setSNS(null);
        } else {
          const name = primaryName + ".six";
          setSNS(name);
        }
      }
    }
    load();
  }, [account]);

  return (account.Ctokens.length || []) > 0 ? (
    <SetPrimaryStyle>
      <Select
        value={sns}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownRoundedIcon}
      >
        {(account.Ctokens || []).map((token) => (
          <MenuItem key={token.token_id} value={`${token.name}.six`}>
            {token.name}.six
          </MenuItem>
        ))}
      </Select>
      <Button size="large" onClick={handleSetPrimmary}>
        {t("setAsPrimary")}
      </Button>
    </SetPrimaryStyle>
  ) : (
    <></>
  );
};

export default SetPrimary;
