import { Box, Skeleton, styled, Typography } from "@mui/material";
import React from "react";

const WrapperStyle = styled(Box)`
  display: flex;
  min-height: 36px;
  flex-wrap: wrap;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 16px 0;

  .MuiTypography-root {
    word-break: break-word;
  }

  &:last-child {
    border: none;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    align-items: center;
    flex-direction: row;
    border: none;
    padding: 0;
  }
`;

const InlineFormat = ({
  title,
  value,
  action,
  children,
  isLoading,
  loadingWidth,
  ...props
}) => {
  return (
    <WrapperStyle {...props}>
      {children}

      {title && (
        <Typography variant="body2" width="160px" fontWeight="bold">
          {title}
        </Typography>
      )}

      {isLoading ? (
        <Skeleton
          variant="text"
          animation="wave"
          width={loadingWidth || "70%"}
        />
      ) : (
        <>
          {value && (
            <Typography variant="body2" color="text.secondary" mr={0.5}>
              {value}
            </Typography>
          )}

          {action && (
            <Box
              display="flex"
              alignItems="center"
              flexGrow={1}
              pt={{ xs: 1, sm: 0 }}
            >
              {action}
            </Box>
          )}
        </>
      )}
    </WrapperStyle>
  );
};

export default InlineFormat;
