import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { Box, Typography } from "@mui/material";
import React from "react";

const Dot = () => {
  return <FiberManualRecordRoundedIcon sx={{ fontSize: "8px", mr: 1 }} />;
};

const FaqTh = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          การลงทะเบียนโดเมน .SIX มีค่าใช้จ่ายเท่าไร?
        </Typography>
        <Typography color="text.secondary">
          ปัจจุบันค่าลงทะเบียนโดเมน กำหนดราคาไว้ดังนี้
        </Typography>
        <Typography whiteSpace="pre-line" color="text.secondary" pl={3} pt={1}>
          <Dot />3 ตัวอักษร .six ชื่อละ: 1500 six ต่อปี
          <br />
          <Dot />4 ตัวอักษร .six ชื่อละ: 300 six ต่อปี
          <br />
          <Dot />5 ตัวอักษร .six ชื่อละ: 150 six ต่อปี
          <br />
          <Dot />6 - 25 ตัวอักษร .six ชื่อละ: 50 ต่อปี
          <br />
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          ฉันสามารถตั้งชื่อโดเมนในลักษณะใดได้บ้าง?
        </Typography>
        <Typography whiteSpace="pre-line" color="text.secondary" pl={3} py={1}>
          <Dot />
          ชื่อมีความยาว 3 - 25 ตัวอักษร
          <br />
          <Dot />
          ขึ้นต้นด้วยตัวอักษรเท่านั้น ลงท้ายด้วยเลข หรือตัวอักษรเท่านั้น
          (ตัวอักษรใช้พิมพ์เล็กได้เท่านั้น)
          <br />
          <Dot />
          ใส่ตัวเลข[0-9] ตัวอักษร และ "-" ได้
          <br />
        </Typography>
        <Typography color="text.secondary">
          หมายเหตุ:
          การตั้งชื่อโดเมนไม่อนุญาตให้ใช้คำที่ไม่สุภาพหรือมีคำที่ไม่เหมาะสม
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          การกระทำใดที่ทำให้การเป็นเจ้าของชื่อโดเมนเป็นโมฆะ
        </Typography>
        <Typography color="text.secondary">
          ซื้อโดเมน .SIX ผ่าน Smart contract โดยไม่ได้ใช้ระบบของ SIX
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          ถ้าหากลืมต่ออายุการลงทะเบียน จะทำอย่างไร
        </Typography>
        <Typography color="text.secondary">
          ถ้าชื่อที่จดทะเบียนของคุณหมดอายุ{" "}
          <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
            คุณจะมีระยะเวลาผ่อนผันทั้งหมด 90 วัน
          </span>{" "}
          ซึ่งในระหว่างนั้นคุณจะไม่สามารถทำการเปลี่ยนแปลงใดๆได้
          แต่คุณจะยังมีโอกาสจดทะเบียนในชื่อเดิมได้
          แต่หลังจากหมดระยะเวลาผ่อนผัน90 วัน
          และถ้าคุณลืมต่ออายุการจดทะเบียนทุกคนจะมีสิทธิ์ลงทะเบียนในชื่อนั้นได้
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          หลังจากที่ซื้อชื่อโดเมนไปแล้วไปแล้ว
          เป็นไปได้ไหมที่จะเปลี่ยนที่อยู่ของกระเป๋า?
        </Typography>
        <Typography color="text.secondary">
          คุณสามารถแก้ไขที่อยู่ที่เชื่อมโยงกับชื่อของคุณได้ทุกเมื่อที่คุณต้องการ
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          สามารถสร้างโดเมนย่อยได้ไหม?
        </Typography>
        <Typography color="text.secondary">
          คุณสามารถสร้างโดเมนย่อยได้ตามที่คุณต้องการ
          หรือแม้แต่การตั้งค่าและมอบความเป็นเจ้าของให้แก่ผู้อื่น
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          บัญชี โดเมน .SIX ทั้งหมด สามารถจัดการได้ที่ไหน?
        </Typography>
        <Typography color="text.secondary">
          ไปที่เว็บไซต์ SIX Name Service และคลิกที่ 'บัญชีของฉัน'
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          ฉันจะโอนโดเมนที่จดทะเบียน .six ให้คนอื่นได้อย่างไร?
        </Typography>
        <Typography color="text.secondary">
          หากต้องการโอนโดเมน .six ไปยังที่อยู่อื่น ให้ไปที่ส่วนของ ผู้จดทะเบียน
          ในบัญชีของฉัน แล้วคลิกปุ่ม ‘โอนสิทธิ์’
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          ผู้จดทะเบียน และ ผู้ควบคุม คืออะไร?
        </Typography>
        <Typography color="text.secondary">
          เจ้าของชื่อ .six คือ ผู้จดทะเบียน
          โดยผู้จดทะเบียนสามารถโอนความเป็นเจ้าของไปยังบัญชีอื่นและเปลี่ยนที่อยู่ของผู้ควบคุมได้
          อีกทั้งผู้ลงทะเบียนยังเป็นเจ้าของ Token NFT ที่แสดงชื่ออีกด้วย
        </Typography>
      </Box>
    </>
  );
};

export default FaqTh;
