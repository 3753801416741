import React, { useState, useEffect } from "react";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import SNSItem from "../components/SNSItem";
import { useControllerClient } from "../hooks/useControllerClient";
import { useSearchContext } from "../store";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import { IsValidationName } from "../utils/Helper";
import { ethers } from "ethers";
import { generateVirtualPic, pinFileToIPFS } from "../utils/NFTPic";

const namehash = require('eth-ens-namehash')
const Web3 = require('web3')
const fs = require('fs')

const SearchPage = () => {
  const navigate = useNavigate();
  const { name } = useParams();
  const [, copy] = useCopyToClipboard();
  const [detailLoaded, setDetailLoaded] = useState(false);
  const clientController = useControllerClient();
  const { search, action, current, reloadCurrent, isErrorDomain } =
    useSearchContext();
  const isDetailPage = useMatch(`/search/${name}/detail`);

  useEffect(() => {
    if (reloadCurrent) {
      setDetailLoaded(true);
      action.setReloadCurrent(false);
    }
  }, [reloadCurrent]);

  useEffect(() => {
    async function load() {
      action.setIsErrorDomain(false);
      action.setIsAvailable(null);
      if (detailLoaded) setDetailLoaded(false);
      if (clientController) {
        action.setNameSearch(name);
        action.setNameCurrent(name);

        var token_id = Web3.utils.sha3(name);
        token_id = Web3.utils.toBN(token_id);

        action.setTokenIdCurrent(token_id);

        var domainName = Web3.utils.sha3(name);
        domainName = Web3.utils.toBN(domainName);

        const [isValid] = IsValidationName(name);
        if (!isValid) {
          var domain_avail = await clientController.available(domainName).call();
          console.log(domain_avail)
          action.setIsAvailable(domain_avail);
          if (!domain_avail) setDetailLoaded(true);
        } else {
          // error
          action.setIsErrorDomain(true);
        }

        const img = await generateVirtualPic(search.name);
        action.setTokenUriVirtualCurrent(img);
      }
    }
    load();


  }, [clientController, search.name]);

  return isDetailPage ? (
    <Outlet />
  ) : search.isAvailable != null ? (current.token_uri_virtual && (
    <SNSItem
      large
      name={`${search.name || name}.six`}
      imageSource={current.token_uri_virtual}
      // expire="Expires 2023.10.13 at 07:39 (UTC+07:00)"
      status={search.isAvailable ? "available" : "unavailable"}
      onClick={() => {
        navigate(`/search/${name}/detail`);
      }}
      onCopy={() => copy(`${search.name || name}.six`)}
    />
  )) : null;
};

export default SearchPage;
