import bg_1 from "../images/nft_bg/NFTnodeVer3_1.svg";
import bg_2 from "../images/nft_bg/NFTnodeVer3_2.svg";
import bg_3 from "../images/nft_bg/NFTnodeVer3_3.svg";
import bg_4 from "../images/nft_bg/NFTnodeVer3_4.svg";
import "../stylesheets/fonts.scss"

const axios = require('axios')
const FormData = require('form-data')
const { Readable } = require('readable-stream');

export function generateVirtualPic(text) {
    return new Promise((resolve, reject) => {
        const text_six = text + ".SIX";
        const svgRef = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgRef.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svgRef.setAttribute("viewBox", "0 0 1000 1000");

        // create a new text element
        const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
        textElement.setAttribute("x", "5%");
        textElement.setAttribute("y", "92%");
        if(text_six.length > 16) {
            textElement.setAttribute("font-size", "18px");
        }
        else {
            textElement.setAttribute("font-size", "24px");
        }
        textElement.setAttribute("fill", "white");
        textElement.setAttribute("font-family", "Montserrat, sans-serif");
        textElement.textContent = text_six;

        // add the text element to the SVG
        svgRef.appendChild(textElement);
        var validSvg;
        switch (text.length) {
            case 3:
                validSvg = bg_1
                break
            case 4:
                validSvg = bg_2
                break
            case 5:
                validSvg = bg_3
                break;
            default:
                validSvg = bg_4
        }
        fetch(validSvg)
            .then((response) => response.text())
            .then((svgRaw) => {
                const parser = new DOMParser();
                const svgDOM = parser.parseFromString(svgRaw, "image/svg+xml");
                const svgNode = svgDOM.documentElement;
                // append the generated text element to the SVG node
                svgNode.appendChild(textElement);

                // convert SVG node to XML string
                const svgData = new XMLSerializer().serializeToString(svgNode);

                // create image element and set source to SVG data URL
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;
                  
                    // draw image onto canvas
                    const ctx = canvas.getContext("2d");
                    
                    // create rounded rectangle clip path
                    const radius = 60; // adjust radius as desired
                    ctx.beginPath();
                    ctx.moveTo(radius, 0);
                    ctx.lineTo(canvas.width - radius, 0);
                    ctx.quadraticCurveTo(canvas.width, 0, canvas.width, radius);
                    ctx.lineTo(canvas.width, canvas.height - radius);
                    ctx.quadraticCurveTo(canvas.width, canvas.height, canvas.width - radius, canvas.height);
                    ctx.lineTo(radius, canvas.height);
                    ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - radius);
                    ctx.lineTo(0, radius);
                    ctx.quadraticCurveTo(0, 0, radius, 0);
                    ctx.closePath();
                    ctx.clip();
                  
                    ctx.drawImage(img, 0, 0);
                  
                    // convert canvas to PNG data URL
                    const dataURL = canvas.toDataURL("image/png");
                  
                    resolve(dataURL);
                  };
                img.src = "data:image/svg+xml;base64," + Buffer.from(svgData).toString('base64');
            })
            .catch((error) => reject(error));
    });
}

export function pinFileToIPFS(domainName, img) {
  return new Promise((resolve, reject) => {
    const requestBody = {
      name: domainName,
      imgData: img,
    };
    axios.post(process.env.REACT_APP_PINATA_API_URL, requestBody)
      .then(res => {
        console.log(res);
        resolve(res.data.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}
  