import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import "./initMetamaskWithChain";
import AccountPage from "./pages/AccountPage";
import AccountDetailPage from "./pages/AccountDetailPage";
import DetailPage from "./pages/DetailPage";
import FaqPage from "./pages/FaqPage";
import LandingPage from "./pages/LandingPage";
import SearchPage from "./pages/SearchPage";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import "./i18n";
import SearchProvider from "./store";
import { SnackbarProvider } from "notistack";
import VerificationSuccessPage from "./pages/VerificationSuccessPage";
import VerificationFailedPage from "./pages/VerificationFailedPage";
import UnsubscribedPage from "./pages/UnsubscribedPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: "/search/:name",
        element: <SearchPage />,
        children: [
          {
            path: "detail",
            element: <DetailPage />,
          },
        ],
      },
      {
        path: "/account",
        element: <AccountPage />,
      },
      {
        path: "/account/:name",
        element: <AccountDetailPage />,
        children: [
          {
            path: "detail",
            element: <DetailPage />,
          },
        ],
      },
      {
        path: "/faq",
        element: <FaqPage />,
      },
      {
        path: "/verification-success",
        element: <VerificationSuccessPage />,
      },
      {
        path: "/verification-failed",
        element: <VerificationFailedPage />,
      },
      {
        path: "/unsubscribed",
        element: <UnsubscribedPage />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <SearchProvider>
          <RouterProvider router={router} />
        </SearchProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
