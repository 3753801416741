import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";

const CopyButton = ({ onClick, ...props }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Tooltip title="Copied!" placement="top" arrow open={isCopied}>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
          setIsCopied(true);

          setTimeout(() => {
            setIsCopied(false);
          }, 1000);
        }}
        {...props}
      >
        <ContentCopyRoundedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
