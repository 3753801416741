import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { Box, Button, Tab, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import CopyButton from "../components/CopyButton";
import CustomTab from "../components/CustomTab";
import DetailTab from "../components/DetailTab";
import RegisterTab from "../components/RegisterTab";
import Status from "../components/Status";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import sample from "../images/sample.png";
import { useSearchContext } from "../store";

const DetailPage = () => {
  const [currentTap, setCurrentTap] = useState(0);
  const { name } = useParams();
  const { search, account, current } = useSearchContext();
  const [, copy] = useCopyToClipboard();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { t } = useTranslation();
  const searchOrAccount = useMatch(`/search/${name}/detail`);
  const status = searchOrAccount
    ? search.isAvailable
      ? "available"
      : "unavailable"
    : "";

  const handleChange = (e, newValue) => {
    setCurrentTap(newValue);
  };

  const onBack = () => {
    if (!searchOrAccount) {
      navigate("/account");
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    async function get() {
      if (search) {
        setCurrentTap(search.isAvailable ? 0 : 1);
      }
    }
    get();
  }, [search]);

  return (
    <>
      <Button
        startIcon={<KeyboardArrowLeftRoundedIcon />}
        onClick={onBack}
        sx={{ color: palette.text.secondary, m: "-16px 0 16px -8px" }}
      >
        {t("back")}
      </Button>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <img
          src={current.token_uri_virtual || current.token_uri || sample}
          alt=""
          width="40px"
        />
        <Typography variant="h5" sx={{ mr: "auto", ml: 2 }}>
          {current.name || name}.six
          <CopyButton
            onClick={() => copy(`${current.name || name}.six`)}
            sx={{ ml: 1 }}
          />
        </Typography>
        <Status status={status} sx={{ ml: 2 }} />
      </Box>

      <CustomTab value={currentTap} onChange={handleChange} status={status}>
        <Tab label={t("register")} disabled={!search.isAvailable} />
        <Tab label={t("details")} disabled={search.isAvailable} />
      </CustomTab>

      <RegisterTab hidden={!search.isAvailable} />
      <DetailTab hidden={search.isAvailable} />
    </>
  );
};

export default DetailPage;
