import {
  Alert,
  Box,
  Button,
  Collapse,
  InputAdornment,
  OutlinedInput,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import bg from "../images/search.png";
import { useSearchContext } from "../store";
import { IsValidationName } from "../utils/Helper";

const SearchStyle = styled(Box)`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 56px;
  width: 100%;

  background: white;
  border-radius: 8px;

  ul {
    list-style-position: inside;
  }

  img {
    position: absolute;
    top: 50%;
    left: -60px;
    height: calc(100% + 96px);
    width: calc(100% + 220px);
    transform: translateY(-50%);
    max-width: initial;
  }

  .MuiOutlinedInput-root {
    font-size: inherit;
    flex-grow: 1;
    border-radius: 8px 0 0 8px;
    background: linear-gradient(
      180deg,
      #ffffff 60%,
      rgba(255, 255, 255, 0) 130%
    );

    * {
      font-size: inherit;
    }

    input {
      padding-left: 16px;
      padding-right: 16px;
    }

    fieldset {
      border: none;
    }

    .MuiInputAdornment-root {
      height: auto;
      position: relative;

      &:before {
        content: "";
        height: calc(100% + 4px);
        width: 2px;
        background: ${({ theme }) => theme.palette.primary.main};
        position: absolute;
        left: -16px;
      }

      p {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  button {
    text-transform: uppercase;
    flex-shrink: 0;
    padding: 0 16px;
    background: ${({ theme }) => theme.palette.primary.main} !important;
    color: white;
    border-radius: 0 8px 8px 0;
    font-size: inherit;
    transition: 0.2s;
    min-width: 96px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    button {
      padding: 0 24px;
      min-width: 120px;
    }

    input {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

const Search = ({ large }) => {
  const { search, action } = useSearchContext();
  const [inputName, setInputName] = useState("");
  // const [isError, setIsError] = useState(!!isErrorDomain);
  const [isWarning, setIsWarning] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [isErrorText, setisErrorText] = useState(false);
  const [errorSearch, setErrorSearch] = useState({
    isErrorText: false,
    isErrorStartEnd: false,
    isErrorLenght: false,
    isErrorBadWord: false,
  });
  const { breakpoints } = useTheme();
  const md = useMediaQuery(breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const onSearch = () => {
    if (inputName) {
      action.setNameSearch(inputName);
      checkIsValid(inputName);
      navigate(`/search/${encodeURIComponent(inputName)}`);
    }
  };

  useEffect(() => {
    if (search.name.length > 0) {
      setInputName(search.name);
      checkIsValid(search.name);
    }
  }, [search]);

  useEffect(() => {
    if (!location.pathname.includes("search")) {
      // action.clearCurrent();
      setInputName("");
      setIsWarning(false);
    }
  }, [location.pathname]);

  function checkIsValid(name) {
    const [isValid, errors] = IsValidationName(name);
    setIsWarning(isValid);
    if (isValid) setErrorSearch(errors);

    // console.log({ isValid, errors });
  }

  return (
    <>
      <SearchStyle
        sx={{
          height: large ? "60px" : "52px",
          fontSize: large ? "1.25rem" : "1.125rem",
          maxWidth: large ? "720px" : "initial",
        }}
      >
        <img src={bg} alt="" />
        <OutlinedInput
          value={inputName}
          placeholder={t("searchNameOrAddress")}
          endAdornment={
            md ? <InputAdornment position="end">. SIX</InputAdornment> : <></>
          }
          autoFocus={large}
          onChange={(e) => {
            setIsWarning(false);
            setInputName(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch();
            }
          }}
        />

        <Button
          // startIcon={
          //   isLoading ? <CircularProgress color="inherit" size="20px" /> : null
          // }
          size="large"
          onClick={onSearch}
        >
          {t("search")}
        </Button>
      </SearchStyle>

      <Box
        mt={2}
        width="100%"
        display="grid"
        rowGap={2}
        sx={{ position: "relative", zIndex: 1 }}
      >
        {/* <Collapse in={isError}>
          <Alert
            severity="error"
            elevation={1}
            onClose={() => {
              setIsError(false);
              action.setIsErrorDomain("");
            }}
          >
            <Typography variant="body2" whiteSpace="pre-line">
              {t("errorName")}
            </Typography>
          </Alert>
        </Collapse> */}

        <Collapse in={isWarning}>
          <Alert
            severity="warning"
            elevation={1}
            onClose={() => {
              setIsWarning(false);
            }}
          >
            {/* <AlertTitle>Warning</AlertTitle> */}
            <ul style={{ listStylePosition: "inside" }}>
              {errorSearch.isErrorLenght && (
                <li>
                  <Typography variant="body2" whiteSpace="pre-line">
                    {t("errorName01")}
                  </Typography>
                </li>
              )}
              {errorSearch.isErrorText && (
                <li>
                  <Typography variant="body2" whiteSpace="pre-line">
                    {t("errorName03")}
                  </Typography>
                </li>
              )}
              {errorSearch.isErrorStartEnd && (
                <li>
                  <Typography variant="body2" whiteSpace="pre-line">
                    {t("errorName02")}
                  </Typography>
                </li>
              )}
              {errorSearch.isErrorBadWord && (
                <li>
                  <Typography variant="body2" whiteSpace="pre-line">
                    {t("errorName04")}
                  </Typography>
                </li>
              )}
            </ul>
          </Alert>
        </Collapse>
      </Box>
    </>
  );
};

export default Search;
