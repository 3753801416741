import React, { createContext, useContext, useState } from "react";

const initialSearch = {
  search: {
    name: "",
    isValidName: false,
    isAvailable: null
  },
  account: {
    Rtokens: [],
    Ctokens: [],
    Utokens: [],
    address: "",
    primary: {
      name: null,
      baseUri: null
    },
    hashNode: null
  },
  current: {
    name: "",
    token_id: "",
    token_uri: "",
    token_uri_virtual: "",
    parent: null,
    registrant: null,
    controller: null,
    controllerOption: null,
    remindEmail: null,
  },
  isErrorDomain: false,
  reloadCurrent: false,
  connected: false,
  errorMessage: ""
};

const SearchContext = createContext({});

export function useSearchContext() {
  return useContext(SearchContext);
}

export default function SearchProvider({ children }) {
  const [state, setState] = useState(initialSearch);

  function setAccountHashNode(hashNode) {
    setState((prev) => ({ ...prev, account: { ...prev.account, hashNode } }));
  }

  function setPrimaryName(name) {
    setState((prev) => ({ ...prev, account: { ...prev.account, primary: {...prev.account.primary, name}}}));
  }

  function setPrimaryBaseUri(baseUri) {
    setState((prev) => ({ ...prev, account: { ...prev.account, primary: {...prev.account.primary, baseUri}}}));
  }

  function setReloadCurrent(reloadCurrent) {
    setState((prev) => ({ ...prev, reloadCurrent }));
  }

  function setNameSearch(name) {
    setState((prev) => ({ ...prev, search: { ...prev.search, name } }));
  }

  function setIsValidName(isValidName) {
    setState((prev) => ({ ...prev, search: { ...prev.search, isValidName } }));
  }

  function setIsAvailable(isAvailable) {
    setState((prev) => ({ ...prev, search: { ...prev.search, isAvailable } }));
  }

  function clearCurrent() {
    setState((prev) => ({ ...prev, current: initialSearch.current }));
  }

  function setCurrent(current) {
    setState((prev) => ({ ...prev, current }));
  }

  function setNameCurrent(name) {
    setState((prev) => ({ ...prev, current: { ...prev.current, name } }));
  }

  function setTokenIdCurrent(token_id) {
    setState((prev) => ({ ...prev, current: { ...prev.current, token_id } }));
  }

  function setTokenUriCurrent(token_uri) {
    setState((prev) => ({
      ...prev,
      current: { ...prev.current, token_uri }
    }));
  }

  function setTokenUriVirtualCurrent(token_uri_virtual) {
    setState((prev) => ({
      ...prev,
      current: { ...prev.current, token_uri_virtual }
    }));
  }

  function setRegistrantCurrent(registrant) {
    setState((prev) => ({ ...prev, current: { ...prev.current, registrant } }));
  }

  function setControllerCurrent(controller) {
    setState((prev) => ({ ...prev, current: { ...prev.current, controller } }));
  }

  function setParentCurrent(parent) {
    setState((prev) => ({ ...prev, current: { ...prev.current, parent } }));
  }

  function setExpireCurrent(expire) {
    setState((prev) => ({ ...prev, current: { ...prev.current, expire } }));
  }

  function setRemindEmailCurrent(remindEmail) {
    setState((prev) => ({ ...prev, current: { ...prev.current, remindEmail } }));
  }

  //   function setHasPermissionn(hasPermission) {
  //     setState((prev) => ({ ...prev, hasPermission }));
  //   }

  function setRTokens(Rtokens) {
    setState((prev) => ({ ...prev, account: { ...prev.account, Rtokens } }));
  }

  function setCTokens(Ctokens) {
    setState((prev) => ({ ...prev, account: { ...prev.account, Ctokens } }));
  }

  function setUTokens(Utokens) {
    setState((prev) => ({ ...prev, account: { ...prev.account, Utokens } }));
  }

  function setAddress(address) {
    setState((prev) => ({ ...prev, account: { ...prev.account, address } }));
  }

  function setIsErrorDomain(isErrorDomain) {
    setState((prev) => ({ ...prev, isErrorDomain }));
  }

  function setErrorMessage(errorMessage) {
    setState((prev) => ({ ...prev, errorMessage }));
  }

  function setConnected(connected) {
    setState((prev) => ({ ...prev, connected }));
  }

  function setControllerOption(controllerOption) {
    setState((prev) => ({ ...prev, current: { ...prev.current, controllerOption } }));
  }

  const store = {
    ...state,
    action: {
      setAccountHashNode,
      setPrimaryName,
      setPrimaryBaseUri,
      setNameSearch,
      setIsValidName,
      setIsAvailable,
      setRTokens,
      setCTokens,
      setUTokens,
      setAddress,
      clearCurrent,
      setCurrent,
      setNameCurrent,
      setTokenIdCurrent,
      setTokenUriCurrent,
      setTokenUriVirtualCurrent,
      setRegistrantCurrent,
      setControllerCurrent,
      setParentCurrent,
      setExpireCurrent,
      setRemindEmailCurrent,
      setIsErrorDomain,
      setReloadCurrent,
      setErrorMessage,
      setConnected,
      setControllerOption
    }
  };

  return (
    <SearchContext.Provider value={store}>{children}</SearchContext.Provider>
  );
}
