import Web3 from "web3";
import { useState, useEffect } from "react";
import { initChain ,getChainId, suggestChain } from "../utils/MetamaskHelper";
const contractABI = require('../abis/BaseRegistrarImplementation.json');


const chainId = process.env.REACT_APP_CHAIN_ID;

export function useRegistrarClient() {
  const [client, setClient] = useState(null);

  useEffect(() => {
    async function get() {
      if (!client) {
        if (typeof window.ethereum === "undefined") {
          alert("Please install the MetaMask extension");
          return;
        }

        var provider = new Web3.providers.HttpProvider(process.env.REACT_APP_RPC_URL);
        var web3Instance = new Web3(provider);
        try {
          
          const registrarContract = new web3Instance.eth.Contract(contractABI, process.env.REACT_APP_CONTRACT_ADDRESS_REGISTRAR);

          setClient(registrarContract.methods);
        } catch (error) {
          console.error(error);
        }
      }
    }

    get();
  }, [window.ethereum]);

  return client;
}
