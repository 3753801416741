import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import DialogTemplate from "./DialogTemplate";
import Web3 from "web3";

const provider = new Web3.providers.HttpProvider(process.env.REACT_APP_RPC_URL);
const web3 = new Web3(window.ethereum);

// Sign the request using the public key of the connected wallet
const signMessage = async message => {
  const accounts = await web3.eth.getAccounts();
  if (accounts.length === 0) {
    throw new Error('No accounts found in the connected wallet.');
  }
  const address = accounts[0]
  const signature = await web3.eth.personal.sign(message, address, '');
  return signature
};

const DialogRemindEmail = ({ tokenId, name, onClose, ...props }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language || 'en';
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [target, setTarget] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const signature = await signMessage("Remind");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${signature}`
        }
      };
      await axios.post(process.env.REACT_APP_REMIND_API_URL, { tokenId, email: target, snsName: name, locale: currentLocale }, config);
      enqueueSnackbar(t("verifyEmailSent"), { variant: "success" });
      onClose();
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      } else if (error.request) {
        enqueueSnackbar(t("serverError"), { variant: "error" });
      } else {
        enqueueSnackbar(t("unknownError"), { variant: "error" });
      }
    }
    setLoading(false);
  };

  return (
    <DialogTemplate
      title={t("remindEmail")}
      caption={t("remindEmailCaption")}
      canClose={!loading}
      onClose={onClose}
      {...props}
    >
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
        <TextField
          disabled={loading}
          size="small"
          placeholder={t("remindEmailPlaceholder")}
          sx={{ py: 0, flexGrow: 1 }}
          value={target}
          onChange={e => {
            setTarget(e.target.value);
          }}
        />
        <LoadingButton
          size="small"
          color="primary"
          sx={{
            ml: { xs: "auto", sm: 2 },
            mt: { xs: 2, sm: 0 },
            height: "auto"
          }}
          onClick={handleSubmit}
          loading={loading}
          variant="contained"
          disabled={loading}
        >
          {t("submit")}
        </LoadingButton>
      </Box>
    </DialogTemplate>
  );
};

export default DialogRemindEmail;
