import { styled, Tabs } from "@mui/material";
import React from "react";
import useStatusColor from "../hooks/useStatusColor";

const TabsStyle = styled(Tabs)`
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.palette.divider};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const CustomTab = ({ children, status, ...props }) => {
  const color = useStatusColor(status);

  return (
    <TabsStyle
      sx={{
        ".MuiTabs-indicator": { background: color },
        ".Mui-selected": { color },
      }}
      {...props}
    >
      {children}
    </TabsStyle>
  );
};

export default CustomTab;
