import { Typography, styled } from "@mui/material";

const WrapperStyle = styled("div")`
  display: flex;
  align-items: center;

  .MuiTypography-root {
    color: #69d343;
  }
`;

const LoadingStyle = styled("div")`
  display: flex;
  margin-left: 16px;

  span {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    animation: animate 1s linear infinite;
    animation-direction: alternate-reverse;
    display: block;
    margin: 0 3px;
    transition: 0.2s;

    &:nth-of-type(1) {
      animation-delay: 0s;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
    &:nth-of-type(4) {
      animation-delay: 0.6s;
    }
    &:nth-of-type(5) {
      animation-delay: 0.8s;
    }
    &:nth-of-type(6) {
      animation-delay: 1s;
    }
  }

  @keyframes animate {
    0% {
      background-color: #e8ffe0;
    }
    20% {
      background-color: #d6fac9;
    }
    40% {
      background-color: #c2f4b0;
    }
    60% {
      background-color: #9be980;
    }
    80% {
      background-color: #69d343;
    }
    100% {
      background-color: #2fa306;
    }
  }
`;

const LoadingPercent = ({ percent }) => {
  return percent !== undefined && percent <= 100 ? (
    <WrapperStyle>
      <LoadingStyle>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </LoadingStyle>
      <Typography variant="caption" ml={1}>
        {percent}%
      </Typography>
    </WrapperStyle>
  ) : (
    <></>
  );
};

export default LoadingPercent;
