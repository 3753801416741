import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { Box, Button, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTab from "../components/CustomTab";
import SNSItem from "../components/SNSItem";
import SetPrimary from "../components/SetPrimary";
import { useControllerClient } from "../hooks/useControllerClient";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import { useRegistrarClient } from "../hooks/useRegistrarClient";
import sample from "../images/sample.png";
import { useSearchContext } from "../store";
import { getAccount } from "../utils/MetamaskHelper";

const AccountPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clientController = useControllerClient();
  const clientRegistrar = useRegistrarClient();
  const { account, action, search } = useSearchContext();
  const [currentTab, setCurrentTab] = useState(0);
  const [, copy] = useCopyToClipboard();
  const Web3 = require("web3");

  const handleChange = (e, newValue) => {
    console.log(newValue)
    setCurrentTab(newValue);
  };

  const handleClickExplorer = () => {
    window.location.replace(process.env.REACT_APP_EVM_BLOCK_EXPLORER);
  };

  useEffect(() => {
    action.setNameSearch({
      name: "",
      isValidName: false,
      isAvailable: null,
    });
    action.setIsAvailable(false);
  }, []);

  useEffect(() => {
    let intervalId;
    async function load() {
      if (clientController && clientRegistrar) {
        const key = await getAccount();
        const registrantList = await clientController
          .getDomainRegistrantList(key)
          .call();
        const underGracePeriodList = await clientController
          .getDomainUnderGracePeriodList(key)
          .call();
        const controllerList = await clientController
          .getDomainControllerList(key)
          .call();
        const primaryName = await clientController.getPrimaryName(key).call();
        if (primaryName != "") {
          console.log(primaryName);
          action.setPrimaryName(primaryName);
          const primaryTokenId = await Web3.utils.sha3(primaryName);
          const baseURI = await clientRegistrar.tokenURI(primaryTokenId).call();
          action.setPrimaryBaseUri(baseURI);
        } else {
          action.setPrimaryName(null);
          action.setPrimaryBaseUri(null);
        }
        const tokenObjects = await Promise.all(
          registrantList.map(async (token) => {
            const token_id = await Web3.utils.sha3(token);
            const baseURI = await clientRegistrar.tokenURI(token_id).call();
            return Promise.resolve({
              token_id: token_id,
              name: token,
              baseURI: baseURI,
            });
          })
        );
        const underGracePeriodObjects = await Promise.all(
          underGracePeriodList.map(async (token) => {
            const token_id = await Web3.utils.sha3(token);
            const baseURI = await clientRegistrar.tokenURI(token_id).call();
            return Promise.resolve({
              token_id: token_id,
              name: token,
              baseURI: baseURI,
            });
          })
        );
        const controllerObjects = await Promise.all(
          controllerList.map(async (token) => {
            const token_id = await Web3.utils.sha3(token);
            const baseURI = await clientRegistrar.tokenURI(token_id).call();
            return Promise.resolve({
              token_id: token_id,
              name: token,
              baseURI: baseURI,
            });
          })
        );
        action.setRTokens(tokenObjects);
        action.setCTokens(controllerObjects);
        action.setUTokens(underGracePeriodObjects);
        action.setAddress(key);
        // console.log(tokenObjects);
        // console.log(controllerObjects)
      }
    }
    async function startInterval() {
      await load();
      intervalId = setInterval(load, 30000);
    }

    startInterval();

    return () => clearInterval(intervalId);
  }, [clientController, account.address, account.primary.baseUri]);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" pb={5}>
        {account.primary && (
          <>
            {account.primary.baseUri && (
              <Box mb={2}>
                <img
                  src={
                    account.primary.name && (account.primary.baseUri || sample)
                  }
                  alt=""
                  width="64px"
                />
              </Box>
            )}

            {account.primary.name && (
              <Typography variant="h5" mb={0.5} textAlign="center">
                {account.primary.name && account.primary.name + ".six"}
              </Typography>
            )}
          </>
        )}

        <Typography
          variant="h6"
          color="text.secondary"
          textAlign="center"
          mb={0.5}
        >
          {account.address}
        </Typography>

        {account.address && (
          <Button
            startIcon={<VisibilityRoundedIcon />}
            onClick={handleClickExplorer}
          >
            View on Sixscan
          </Button>
        )}

        <SetPrimary />

        <Typography
          whiteSpace="pre-line"
          textAlign="center"
          color="text.disabled"
        >
          {t("setAsPrimaryDetail")}
        </Typography>
      </Box>

      <CustomTab value={currentTab} onChange={handleChange}>
        <Tab label={t("registrant")} />
        <Tab label={t("controller")} />
        {account.Utokens.length > 0 ? (
          <Tab label={t("under grace period")} />
        ) : null}
      </CustomTab>

      <Box py={2}>
        {currentTab === 0 ? (
          (account.Rtokens || []).map((token) => (
            <SNSItem
              key={token.name}
              name={`${token.name}.six`}
              expire={token.expire}
              imageSource={token.baseURI}
              onClick={() => {
                action.setCurrent(token);
                navigate(`/account/${token.name}/detail`);
              }}
              onCopy={() => copy(`${token.name}.six`)}
              sx={{ background: "transparent" }}
            />
          ))
        ) : (
          currentTab === 1 ? (
            (account.Ctokens || []).map((token) => (
              <SNSItem
                key={token.name}
                name={`${token.name}.six`}
                expire={token.expire}
                imageSource={token.baseURI}
                onClick={() => {
                  action.setCurrent(token);
                  console.log(search.isAvailable);
                  navigate(`/account/${token.name}/detail`);
                }}
                onCopy={() => copy(`${token.name}.six`)}
                sx={{ background: "transparent" }}
              />
            ))
          ) : (
            (account.Utokens || []).map((token) => (
              <SNSItem
                key={token.name}
                name={`${token.name}.six`}
                expire={token.expire}
                imageSource={token.baseURI}
                onClick={() => {
                  action.setCurrent(token);
                  console.log(search.isAvailable);
                  navigate(`/account/${token.name}/detail`);
                }}
                onCopy={() => copy(`${token.name}.six`)}
                sx={{ background: "transparent" }}
              />
            ))
          )
        )}
      </Box>
    </>
  );
};

export default AccountPage;
