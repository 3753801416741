import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
// import { showTranslations } from "translation-check";

const LanguageButton = ({ sx }) => {
  const { i18n } = useTranslation();

  const handleChangeLang = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <Select
      value={i18n.language || "en"}
      onChange={handleChangeLang}
      IconComponent={KeyboardArrowDownRoundedIcon}
      size="small"
      sx={{
        fieldset: { borderColor: "white !important" },
        color: "text.secondary",
        ...sx,
      }}
    >
      <MenuItem value="en">EN</MenuItem>
      <MenuItem value="th">TH</MenuItem>
    </Select>
  );
};

export default LanguageButton;
