import { Box, Typography } from "@mui/material";
import React from "react";
import Search from "../components/Search";
import logo from "../images/logo.png";

const LandingPage = () => {
  return (
    <>
      <Box width={{ xs: "200px", md: "260px" }} mt="-10%">
        <img src={logo} alt="" />
      </Box>

      <Box mb={5} mt={4}>
        <Typography
          variant="h4"
          fontSize={{ xs: "1.75rem", md: "2.25rem" }}
          fontWeight="bold"
          color="#4D4D4D"
          mb={0.25}
          textAlign="center"
          lineHeight={1.4}
        >
          Say Goodbye to Long Addresses – Hello SNS
        </Typography>
        <Typography fontSize="1.25rem" color="#4D4D4D" textAlign="center">
          Make your blockchain experience better with SNS
        </Typography>
      </Box>

      <Box maxWidth="720px" width="100%">
        <Search large />
      </Box>
    </>
  );
};

export default LandingPage;
