import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStatus = (status) => {
  const [name, setName] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (status === "registrant") {
      setName(t("registrant"));
    } else if (status === "available") {
      setName(t("available"));
    } else if (status === "unavailable") {
      setName(t("unavailable"));
    } else {
      setName("");
    }
  }, [status, t]);

  return name;
};

export default useStatus;
