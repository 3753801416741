import { Box, Container, styled } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Search from "./components/Search";
import bg from "./images/bg.png";
import { useSearchContext } from "./store";
import "./stylesheets/global.scss";
import { getAccount } from "./utils/MetamaskHelper";

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  background-image: ${({ isIndexPage }) => (isIndexPage ? `url(${bg})` : "")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const CenterWrapper = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const WhiteWrapper = styled("div")`
  background: white;
  flex-grow: 1;
`;

const SearchWrapper = styled(Container)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function App() {
  const isIndexPage = useMatch("/");
  const isVerifySuccess = useMatch("/verification-success");
  const isVerifyFailed = useMatch("/verification-failed");
  const navigate = useNavigate();
  const { action } = useSearchContext();
  useEffect(() => {
    return window.addEventListener("metamask_keystorechange", async (m) => {
      const address = await getAccount();
      action.setAddress(address);
      navigate(`/account`);
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <Wrapper isIndexPage={isIndexPage}>
        <Header />
        <CenterWrapper>
          {isIndexPage || isVerifySuccess || isVerifyFailed ? (
            <SearchWrapper maxWidth="md">
              <Outlet />
            </SearchWrapper>
          ) : (
            <>
              <Box py={{ xs: 3, md: 5 }}>
                <Container maxWidth="md">
                  <Search />
                </Container>
              </Box>

              <WhiteWrapper>
                <Container maxWidth="md" sx={{ py: { xs: 4, md: 5 } }}>
                  <Outlet />
                </Container>
              </WhiteWrapper>
            </>
          )}
        </CenterWrapper>
        <Footer />
      </Wrapper>
    </>
  );
}

export default App;
