import { badword } from "../config/badword";

export const bigNumberToString = (big) => {
  const number = new Number(big).valueOf() / 10 ** 18;
  let str;
  let parts = number.toString().split(".");
  if (parts.length > 1) {
    const decimals = parts[1].length;
    str = number.toFixed(decimals);
  } else {
    parts = number.toExponential().split("-");
    let decimals = +parts[1];
    if (decimals < 1) {
      decimals = 1;
    }
    str = number.toFixed(decimals);
  }

  return str;
};

export function isBadWord(word) {
  return (
    badword.includes(word.toLowerCase()) ||
    badword.find((w) => word.toLowerCase().includes(w))
  );
}

export function IsValidationName(name) {
  const regex = /^[a-z0-9-]+$/; ///[a-z0-9]/
  const regexStartEnd = /^[[a-z].*[a-z0-9]$/g;
  const isErrorText = !regex.test(name);
  const isErrorStartEnd = !regexStartEnd.test(name);
  const isErrorLenght = !(name.length >= 3 && name.length <= 25);
  const isErrorBadWord = isBadWord(name) || false;

  // console.log({
  //   name,
  //   length: name.length,
  //   moreT: name.length >= 3,
  //   moreTF: name.length <= 25,
  //   isErrorText,
  //   isErrorStartEnd,
  //   isErrorLenght,
  //   isErrorBadWord
  // });

  return [
    isErrorText || isErrorStartEnd || isErrorLenght || isErrorBadWord,
    { isErrorText, isErrorStartEnd, isErrorLenght, isErrorBadWord },
  ];
}

// function validateBadword(word) {
//   let count = 0;
//   var badword_alerts = new Array();

//   for (var i = 0; i < badword.length; i++) {
//     for (var j = 0; j < word.length; j++) {
//       if (
//         badword[i] == word.substring(j, j + badword[i].length).toLowerCase()
//       ) {
//         badword_alerts[count] = word.substring(j, j + badword[i].length);
//         count++;
//       }
//     }
//   }

//   console.log(`validateBadword [${word}] : ${count > 0}`);
//   return count > 0;
// }
