import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import FaqEn from "../components/FaqEn";
import FaqTh from "../components/FaqTh";

const FaqPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <Typography variant="h4" mb={2}>
        {t("faq")}
      </Typography>

      {i18n.language === "en" ? <FaqEn /> : <FaqTh />}
    </>
  );
};

export default FaqPage;
