import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import { Button, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BoxStyle = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 40px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  width: 100%;
`;

const UnsubscribedPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BoxStyle>
      <NotificationsOffOutlinedIcon
        sx={{ color: "text.secondary", fontSize: "5rem", mb: 2 }}
      />
      <Typography variant="h4" align="center" mb={1} whiteSpace="pre-line">
        {t("unsubscribed")}
      </Typography>
      {/* <Typography fontSize="1.25rem" align="center" maxWidth="500px">
        Reference site about Lorem Ipsum, giving information on its origins, as
        well as a random Lipsum generator.
      </Typography> */}
      <Button
        variant="contained"
        sx={{ mt: 2 }}
        onClick={() => {
          navigate("/");
        }}
      >
        {t("ok")}
      </Button>
    </BoxStyle>
  );
};

export default UnsubscribedPage;
