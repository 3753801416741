import { Box, TextField, InputAdornment, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import { useControllerClient } from "../hooks/useControllerClient";
import { useRegistrarClient } from "../hooks/useRegistrarClient";
import { useResolverClient } from "../hooks/useResolverClient";
import { useClaimClient } from "../hooks/useClaimClient";
import DialogTemplate from "./DialogTemplate";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSearchContext } from "../store";
import { useNavigate } from "react-router-dom";
import { CurrencyExchange } from "@mui/icons-material";
import { getAccount, getWeb3Instance, getChainId } from "../utils/MetamaskHelper";
import { toInteger } from "lodash";

const Web3 = require('web3');
const namehash = require('eth-ens-namehash');

const stringValidator = input => {
  if (!input || input === NaN) {
    return "";
  }
  return input;
};

const isAddress = input => {
  return ethers.utils.isAddress(stringValidator(input));
};

const isEndsWithSix = input => {
  return stringValidator(input).endsWith(".six");
};

const validateTarget = target => {
  var input = stringValidator(target);
  if (isAddress(input)) {
    return { input, type: "address" };
  } else if (isEndsWithSix(input)) {
    return { input, type: "name" };
  } else {
    input = input + ".six";
    return { input, type: "name" };
  }
};

const DialogTransfer = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { current, action, connected } = useSearchContext();
  const [target, setTarget] = useState("");
  const clientController = useControllerClient();
  const clientRegistrar = useRegistrarClient();
  const clientResolver = useResolverClient();
  const clientClaim = useClaimClient();
  const { enqueueSnackbar } = useSnackbar();
  // call controller getNodehash(sns_nam)
  // call registry setOwner(node,owner:newOnwer)

  async function handleTransfer() {
    setLoading(true);
    if (!connected) {
      enqueueSnackbar(t("notConnected"), { variant: "error", });
      setLoading(false);
      return
    }
    const recipient = validateTarget(target);
    // console.log({ target, tokenId: current.token_id });
    // setLoading(true);
    const web3Instance = await getWeb3Instance();
    try {
      if (recipient.type === "name") {
        var domain_namehash = namehash.hash(recipient.input);
        domain_namehash = namehash.normalize(domain_namehash);
        var node = await clientResolver.addr(domain_namehash).call();
        if (node == 0x0) {
          enqueueSnackbar(t("snsNotFound"), { variant: "error", });
          setLoading(false);
          return
        }
        else {
          recipient.input = node;
        }
      }
      var account = await getAccount();
      var domainName = Web3.utils.toBN(current.token_id);
      const tx = await clientRegistrar.transferFrom(account, recipient.input, domainName);
      const gasPrice = await web3Instance.eth.getGasPrice();
      const gasLimit = await web3Instance.eth.estimateGas({
        to: process.env.REACT_APP_CONTRACT_ADDRESS_REGISTRAR,
        from: account,
        value: 0,
        data: tx.encodeABI()
      })
      const transactionParameters = {
        to: process.env.REACT_APP_CONTRACT_ADDRESS_REGISTRAR,
        from: account,
        value: Web3.utils.toHex(0),
        nonce: await toInteger(web3Instance.eth.getTransactionCount(account)),
        chainId: await getChainId(),
        gasPrice: Web3.utils.toHex(gasPrice),
        gasLimit: Web3.utils.toHex(gasLimit),
        data: tx.encodeABI()
      };
      window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
        .then(async (transfer) => {
          const transactionReceiptAsync = function () {
            web3Instance.eth.getTransactionReceipt(transfer, (error, receipt) => {
              if (error) {
                console.log(error);
              } else if (receipt == null) {
                setTimeout(
                  () => transactionReceiptAsync(),
                  500);
              } else {
                console.log(receipt);
                action.setRegistrantCurrent(recipient.input);
                enqueueSnackbar(t("transferSuccess"), {
                  variant: "success"
                });
                action.setReloadCurrent(true);
                setLoading(false);
                props.onReload();
              }
            });
          };
          transactionReceiptAsync();
        })
        .catch((err) => {
          // console.log("commit : ", { err });
          if (!err.message.includes("rejected")) {
            enqueueSnackbar(t("registerError"), {
              variant: "error"
            });
            console.error(err);
          }
        });
    }
    catch (err) {
      if (!err.message.includes("rejected")) {
        enqueueSnackbar(t("transferError"), {
          variant: "error"
        });
        console.error("reclaim : ", err);
        props.onClose();
      }
    }

    setLoading(false);
  }

  return (
    <DialogTemplate
      title={t("transfer")}
      caption={t("transferCaption")}
      canClose={!loading}
      {...props}
    >

      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
        <TextField
          disabled={loading}
          size="small"
          placeholder={t("enterSNSOrAddress")}
          sx={{ py: 0, flexGrow: 1 }}
          value={target}
          onChange={e => {
            setTarget(e.target.value);
          }}
          endAdornment={
            isAddress(isAddress) || isEndsWithSix(target) ? null : (
              <InputAdornment position="end">.six</InputAdornment>
            )
          }
        />
        <LoadingButton
          size="small"
          color="primary"
          sx={{
            ml: { xs: "auto", sm: 2 },
            mt: { xs: 2, sm: 0 },
            height: "auto"
          }}
          onClick={handleTransfer}
          loading={loading}
          variant="contained"
          disabled={loading}
        >
          {t("submit")}
        </LoadingButton>
      </Box>
    </DialogTemplate>
  );
};

export default DialogTransfer;
