import { Box, Button, useTheme, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import { useRegistrarClient } from "../hooks/useRegistrarClient";
import { useRegistryClient } from "../hooks/useRegistryClient";
import { useSearchContext } from "../store";
import CopyButton from "./CopyButton";
import DialogExtend from "./DialogExtend";
import DialogRemindEmail from "./DialogRemindEmail";
import DialogSet from "./DialogSet";
import DialogTransfer from "./DialogTransfer";
import InlineFormat from "./InlineFormat";
import RemindMeButton from "./RemindMeButton";
import LeftTime from "./LeftTime";

const namehash = require("eth-ens-namehash");
const Web3 = require("web3");

const DetailTab = ({ hidden, ...props }) => {
  const { name } = useParams();
  const { action, account, current } = useSearchContext();
  const clientRegistrar = useRegistrarClient();
  const clientRegistry = useRegistryClient();
  const [, copy] = useCopyToClipboard();
  const { palette } = useTheme();
  const [showTransfer, setShowTransfer] = useState(false);
  const [showSet, setShowSet] = useState(false);
  const [showExtend, setShowExtend] = useState(false);
  const [showRemindEmail, setShowRemindEmail] = useState(false);
  const [showRemindCalendar, setShowRemindCalendar] = useState(false);
  const [expire, setExpireCurrent] = useState("loading");
  const [graceExpire, setGraceExpireCurrent] = useState("loading");
  const [parent, setParent] = useState("loading");
  const [registrant, setRegistrant] = useState("loading");
  const [controller, setController] = useState("loading");
  const [leftTime, setLeftTime] = useState();
  const [graceLeftTime, setGraceLeftTime] = useState();
  const [isExpired, setIsExpired] = useState(false);
  const { t } = useTranslation();

  const onEmailClick = () => {
    setShowRemindEmail(true);
  };

  const onCalendarClick = () => {
    setShowRemindCalendar(true);
  };

  async function loadConfig() {
    if (clientRegistrar && current.token_id) {
      const config = await clientRegistrar.baseNode().call();
      const tokenId = Web3.utils.sha3(current.name);
      const baseURI = await clientRegistrar.tokenURI(tokenId).call();
      action.setTokenUriCurrent(baseURI);
      if (namehash.hash("six") == config) {
        action.setParentCurrent("six".toUpperCase());
      } else {
        action.setParentCurrent("unknown".toUpperCase());
      }
    }
  }

  async function loadController() {
    if (clientRegistry && clientRegistrar && current.token_id) {
      var domain_namehash = namehash.hash((current.name || name) + ".six");
      const expire_res = await clientRegistrar
        .nameExpires(current.token_id)
        .call();
        const record = await clientRegistry.owner(domain_namehash).call();
      if ((expire_res * 1000)> (Date.now() + (7 * 60 * 1000))) {
        action.setControllerCurrent(record.toLowerCase());
      }
      else {
        action.setControllerCurrent("Not Owned");
      }
    }
  }

  async function loadRegistrant() {
    if (clientRegistrar && current.token_id) {
      try {
        const owner_of = await clientRegistrar.ownerOf(current.token_id).call();
        action.setRegistrantCurrent(owner_of.toLowerCase());
      }
      catch {
        action.setRegistrantCurrent("Not Owned");
      }
    }
  }

  async function loadExpire() {
    if (clientRegistrar && current.token_id) {
      const expire_res = await clientRegistrar
        .nameExpires(current.token_id)
        .call();

      let expStr = await moment.unix(expire_res).format("YYYY.MM.DD hh:mm A");
      const response = await axios.get(
        process.env.REACT_APP_REMIND_STAUS_API_URL + current.token_id
      );
      const email = response.data.data.email;
      action.setRemindEmailCurrent(email);
      action.setExpireCurrent(expStr);
    }
  }

  useEffect(() => {
    if (current) {
      if (current.remindEmail) setExpireCurrent(current.remindEmail);
      if (current.expire) setExpireCurrent(current.expire);
      if (current.expire)
        setGraceExpireCurrent(
          moment(current.expire)
            .add(process.env.REACT_APP_GRACE_PERIOD, "minutes")
            .format("YYYY.MM.DD HH:mm A")
        );
      if (current.parent) setParent(current.parent);
      if (current.registrant) setRegistrant(current.registrant.owner);
      if (current.controller) setController(current.controller.owner);
    }
  }, [current]);

  useEffect(() => {
    if (expire) {
      const a = moment(expire);
      const b = moment(new Date());
      const c = moment(expire).add(process.env.REACT_APP_GRACE_PERIOD, "minutes");
      const d = moment(new Date());

      const years = a.diff(b, "years");
      b.add(years, "years");

      const months = a.diff(b, "months");
      b.add(months, "months");

      const days = a.diff(b, "days");
      b.add(days, "days");

      const hours = a.diff(b, "hours");
      b.add(hours, "hours");

      const mins = a.diff(b, "minutes");

      setLeftTime({ years, months, days, hours, mins });
      const isExpired = years <= 0 && months <= 0 && days <= 0 && hours <= 0 && mins <= 0;
      setIsExpired(isExpired);
      const graceYears = c.diff(d, "years");
      d.add(graceYears, "years");

      const graceMonths = c.diff(d, "months");
      d.add(graceMonths, "months");

      const graceDays = c.diff(d, "days");
      d.add(days, "days");

      const graceHours = c.diff(d, "hours");
      d.add(hours, "hours");

      const graceMins = c.diff(d, "minutes");
      setGraceLeftTime({
        years: graceYears,
        months: graceMonths,
        days: graceDays,
        hours: graceHours,
        mins: graceMins
      });
    }
  }, [expire]);

  useEffect(() => {
    async function load() {
      loadConfig();
      loadExpire();
      loadRegistrant();
    }
    if(!hidden) {
      load();
    }
  }, [
    clientRegistrar,
    current.token_id,
    current.registrant,
    current.controller
  ]);

  useEffect(() => {
    loadController();
  }, [clientRegistry]);

  // useEffect(() => {
  //   if (current.registrant === account.address) {
  //     console.log('1')
  //   } else if (current.controller === account.address) {
  //     console.log('2')
  //   }
  // }, []);

  return (
    <Box
      border={`1px solid ${palette.divider}`}
      px={{ xs: 2, sm: 3 }}
      py={{ xs: 1, sm: 2 }}
      mt={3}
      borderRadius={2}
      hidden={hidden}
      {...props}
    >
      {!hidden && (
        <>
          <InlineFormat
            title={t("parent")}
            value={parent}
            isLoading={parent === "loading"}
            loadingWidth="15%"
          />

          <InlineFormat
            title={t("registrant")}
            value={current.registrant}
            isLoading={current.registrant === "loading"}
            loadingWidth="50%"
            action={
              <>
                <CopyButton onClick={() => copy(current.registrant)} />
                {current.registrant === account.address && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ ml: "auto" }}
                    onClick={() => {
                      setShowTransfer(true);
                    }}
                  >
                    {t("transfer")}
                  </Button>
                )}
              </>
            }
          />

          <InlineFormat
            title={t("controller")}
            value={current.controller}
            isLoading={current.controller === "loading"}
            loadingWidth="50%"
            action={
              <>
                <CopyButton onClick={() => copy(current.controller)} />
                {current.controller === account.address && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ ml: "auto" }}
                    onClick={() => {
                      action.setControllerOption("set");
                      setShowSet(true);
                    }}
                  >
                    {t("set")}
                  </Button>
                )}
                {current.registrant === account.address &&
                  current.controller !== account.address && (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ ml: "auto" }}
                      onClick={() => {
                        action.setControllerOption("reclaim");
                        setShowSet(true);
                      }}
                    >
                      {t("reclaim")}
                    </Button>
                  )}
              </>
            }
          />

          <InlineFormat
            title={t("expirationDate")}
            value={
              <>
                {leftTime && <LeftTime {...leftTime} />}
                {expire}
              </>
            }
            isLoading={expire === "loading"}
            loadingWidth="30%"
            action={
              <>
                <RemindMeButton
                  onEmailClick={onEmailClick}
                  onCalendarClick={onCalendarClick}
                  sx={{ ml: "auto", mr: 1 }}
                />
                {current.remindEmail && (
                  <Typography variant="body2" color="text.secondary" mr={0.5}>
                    ({current.remindEmail})
                  </Typography>
                )}
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setShowExtend(true);
                  }}
                >
                  {t("extend")}
                </Button>
              </>
            }
          />

          {isExpired && (
            <InlineFormat
              title={t("gracePeriod")}
              value={
                <>
                  {graceLeftTime && <LeftTime {...graceLeftTime} />}
                  {graceExpire}
                </>
              }
              isLoading={expire === "loading"}
              loadingWidth="30%"
            />
          )}
          <DialogSet
            snsName={current.name || name}
            open={showSet}
            onClose={() => {
              setShowSet(false);
            }}
            onReload={async () => {
              await loadController();
              setShowSet(false);
            }}
          />
          <DialogTransfer
            tokenId={current.token_id}
            open={showTransfer}
            onClose={() => {
              setShowTransfer(false);
            }}
            onReload={async () => {
              await loadRegistrant();
              setShowTransfer(false);
            }}
          />
          <DialogExtend
            snsName={current.name || name}
            open={showExtend}
            onClose={() => {
              setShowExtend(false);
            }}
            onReload={async () => {
              await loadExpire();
              setShowExtend(false);
            }}
          />
          <DialogRemindEmail
            tokenId={current.token_id}
            name={name}
            open={showRemindEmail}
            onClose={() => {
              setShowRemindEmail(false);
            }}
          />
        </>
      )}
    </Box>
  );
};

export default DetailTab;
