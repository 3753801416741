import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import {
  Box,
  Button,
  LinearProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { toInteger } from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useControllerClient } from "../hooks/useControllerClient";
import { useSearchContext } from "../store";
import {
  getAccount,
  getChainId,
  getSIXBalance,
  getWeb3Instance,
} from "../utils/MetamaskHelper";
import { generateVirtualPic, pinFileToIPFS } from "../utils/NFTPic";
import PeriodPriceForm from "./PeriodPriceForm";
import LoadingPercent from "./LoadingPercent";

const Web3 = require("web3");

const StepperStyle = styled(Stepper)`
  padding: 16px 0 8px 0;

  .MuiStep-root {
    position: relative;
    overflow: hidden;
    padding-bottom: 16px;
    color: ${({ theme }) => theme.palette.text.disabled};

    .MuiLinearProgress-root {
      position: absolute;
      top: 0;
      left: 14px;
      transform: rotate(90deg);
      transform-origin: top left;
      width: 100%;
    }

    .MuiStepLabel-root {
      position: relative;
      z-index: 1;
      padding: 0;
      margin-bottom: 8px;
    }

    &:last-child {
      .MuiLinearProgress-root {
        display: none;
      }
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
      .MuiLinearProgress-root {
        width: 20%;
      }
    }
  }

  .MuiStepContent-root {
    .MuiTypography-root {
      margin-top: -4px;
    }
  }
`;

const StepIconStyle = styled("div")(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: "24px",
  height: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  border: "2px solid",
  borderRadius: "50%",

  "&.active": {
    borderColor: `${theme.palette.success.main} !important`,
    color: `${theme.palette.success.main} !important`,
  },
  "&.completed": {
    borderColor: `${theme.palette.success.main} !important`,
    color: `white !important`,
    background: `${theme.palette.success.main} !important`,
  },
}));

const StepIcon = (props) => {
  const { active, completed, icon } = props;

  return (
    <StepIconStyle
      className={completed ? "completed" : active ? "active" : "inactive"}
    >
      {completed ? (
        <CheckRoundedIcon className="check" fontSize="1rem" />
      ) : active ? (
        <Typography variant="caption">{icon}</Typography>
      ) : (
        <Typography variant="caption">{icon}</Typography>
      )}
    </StepIconStyle>
  );
};

const RegisterTab = ({ children, hidden, ...props }) => {
  const navigate = useNavigate();
  const [disableStep, setDisableStep] = useState(true);
  const [onProgress, setOnProgress] = useState(false);
  const [year, setYear] = useState(1);
  const [price, setPrice] = useState(0);
  const [uuid_str] = useState(uuidv4());
  const [duration, setDuration] = useState(year * (24 * 3600 * 365));
  const [secret, setSecret] = useState(null);
  const { search, current, connected, action } = useSearchContext();
  const client = useControllerClient();
  const [activeStep, setActiveStep] = useState(0);
  const [waitingTime, setWaitingTime] = useState();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const steps = [
    {
      title: t("requestToRegister"),
      detail: t("requestToRegisterDetail"),
    },
    {
      title: t("wait"),
      detail: t("waitDetail"),
    },
    {
      title: t("completeRegister"),
      detail: t("completeRegisterDetail"),
    },
  ];

  useEffect(() => {
    async function get() {
      if (client && search.isAvailable && connected) {
        const balance = await getSIXBalance();
        const dura = year * (60);
        var price = await client.getPrice(current.name, dura).call();
        price = Web3.utils.fromWei(price, "ether");
        console.log(price);
        setPrice(price);

        setDuration(dura);
        const checkBalance = Number(balance) <= Number(price);
        setDisableStep(checkBalance);
        // console.log({ checkBalance, balance, price: pr.price });
      }
    }
    get();
  }, [client, search.name, search.isAvailable, year, connected]);

  useEffect(() => {
    if (!connected) {
      setDisableStep(true);
    }
  }, [connected]);

  const waiting = useCallback(() => {
    let i = 0;

    setWaitingTime(0);

    const timer = setInterval(() => {
      i++;

      if (i < 50) {
        setWaitingTime(i * 2);
      } else {
        clearInterval(timer);
        enqueueSnackbar(t("waitFinish"), {
          variant: "success",
        });
        setActiveStep(2);
        setOnProgress(false);
        setDisableStep(false);
        setWaitingTime();
      }
    }, 1200);
  }, []);

  const requestRegister = async () => {
    setOnProgress(true);
    setDisableStep(true);
    const random = new Uint8Array(32);
    crypto.getRandomValues(random);
    const salt =
      "0x" +
      Array.from(random)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
    setSecret(salt);
    var domainName = Web3.utils.sha3(current.name);
    domainName = Web3.utils.toBN(domainName);
    const web3Instance = await getWeb3Instance();
    try {
      var account = await getAccount();
      const commitments = await client
        .makeCommitment(current.name, account, duration, salt)
        .call();
      // console.log(current.name)
      // console.log(duration)
      //   console.log(commitments)
      const tx = await client.commit(commitments);
      console.log(account);
      const gasPrice = await web3Instance.eth.getGasPrice();
      const gasLimit = await web3Instance.eth.estimateGas({
        to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
        from: account,
        value: 0,
        data: tx.encodeABI(),
      });
      const transactionParameters = {
        to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
        from: account,
        value: Web3.utils.toHex(0),
        nonce: await toInteger(web3Instance.eth.getTransactionCount(account)),
        chainId: await getChainId(),
        gasPrice: Web3.utils.toHex(gasPrice),
        gasLimit: Web3.utils.toHex(gasLimit),
        data: tx.encodeABI(),
      };
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        })
        .then(async (commit) => {
          const transactionReceiptAsync = function () {
            web3Instance.eth.getTransactionReceipt(commit, (error, receipt) => {
              if (error) {
                console.log(error);
              } else if (receipt == null) {
                setTimeout(() => transactionReceiptAsync(), 500);
              } else {
                console.log(receipt);
                setActiveStep(1);
                waiting();
              }
            });
          };
          transactionReceiptAsync();
        })
        .catch((err) => {
          // console.log("commit : ", { err });
          if (!err.message.includes("rejected")) {
            enqueueSnackbar(t("registerError"), {
              variant: "error",
            });
            console.error(err);
          }
          setActiveStep(0);
          setOnProgress(false);
          setDisableStep(false);
        });
    } catch (err) {
      // console.log("getCommitment : ", { err });
      if (!err.message.includes("rejected")) {
        enqueueSnackbar(t("registerError"), {
          variant: "error",
        });
        console.error(err);
      }
      setActiveStep(0);
      setOnProgress(false);
      setDisableStep(false);
    }
  };

  const toRegister = async () => {
    setOnProgress(true);
    setDisableStep(true);
    var domainName = Web3.utils.sha3(current.name);
    domainName = Web3.utils.toBN(domainName);
    const web3Instance = await getWeb3Instance();
    const account = await getAccount();
    console.log(current.name);
    console.log(duration);
    console.log(secret);
    const img = await generateVirtualPic(current.name);
    const ipfsHash = await pinFileToIPFS(current.name, img);
    const baseURI = `${process.env.REACT_APP_PINATA_GATEWAY}/ipfs/${ipfsHash}`;
    console.log(baseURI);
    const tx = await client.registerwithConfig(
      domainName,
      baseURI,
      current.name,
      account,
      duration,
      secret
    );
    var price = await client.getPrice(current.name, duration).call();
    const gasPrice = await web3Instance.eth.getGasPrice();
    console.log(gasPrice);
    const gasLimit = await web3Instance.eth.estimateGas({
      to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
      from: account,
      value: price,
      data: tx.encodeABI(),
    });
    console.log(typeof price);
    const transactionParameters = {
      to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
      from: account,
      value: Web3.utils.toHex(price),
      nonce: await toInteger(web3Instance.eth.getTransactionCount(account)),
      chainId: await getChainId(),
      gasPrice: Web3.utils.toHex(gasPrice),
      gasLimit: Web3.utils.toHex(gasLimit),
      data: tx.encodeABI(),
    };
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      })
      .then((register) => {
        const transactionReceiptAsync = function () {
          web3Instance.eth.getTransactionReceipt(register, (error, receipt) => {
            if (error) {
              console.log(error);
            } else if (receipt == null) {
              setTimeout(() => transactionReceiptAsync(), 500);
            } else {
              console.log(receipt);
              console.log("register finish : ", { register });
              enqueueSnackbar(t("registerSuccess"), { variant: "success" });
              setOnProgress(false);
              setDisableStep(false);
              setActiveStep(3);
            }
          });
        };
        transactionReceiptAsync();
      })
      .catch((err) => {
        if (!err.message.includes("rejected")) {
          enqueueSnackbar(t("registerError"), {
            variant: "error",
          });
          console.error(err);
          setOnProgress(false);
          setDisableStep(false);
        }
      });
  };

  const toSetPrimary = () => {
    action.setIsAvailable(false);
    navigate("/account");
  };

  const onPressButton = () => {
    switch (activeStep) {
      case 0:
        requestRegister();
        break;
      case 2:
        toRegister();
        break;
      case 3:
        toSetPrimary();
        break;

      default:
        break;
    }
  };

  const getStringButton = () => {
    switch (activeStep) {
      case 0:
        return t("requestToRegisterButton");
      case 2:
        return t("register");
      case 3:
        return t("setAsPrimarySNSName");
      default:
        return null;
    }
  };

  const getStringDescriptionBtn = () => {
    switch (activeStep) {
      case 0:
        if (!connected) return t("notConnected");
        if (disableStep && !onProgress) return t("insufficientBalance");
        if (disableStep && onProgress) return null;
        return null;
      case 2:
        if (!connected) {
          return t("notConnected");
        } else {
          if (onProgress) return null;
          return t("clickRegisterToMove");
        }

      default:
        return null;
    }
  };

  return (
    <div hidden={hidden} {...props}>
      {!hidden && (
        <>
          <PeriodPriceForm
            pt={3}
            pb={1}
            year={year}
            setYear={setYear}
            price={price}
          />

          <Typography variant="subtitle1">{t("register3steps")}</Typography>
          {/* <Typography variant="body2" color="text.secondary">
            Favorite the name for easy access in case you close out of your
            browser.
          </Typography> */}

          <StepperStyle
            activeStep={activeStep}
            orientation="vertical"
            connector={null}
          >
            {steps.map((step, index) => (
              <Step key={step.title} expanded>
                <LinearProgress
                  color={index < activeStep ? "success" : "inherit"}
                  variant={
                    index === activeStep - 1 ? "indeterminate" : "determinate"
                  }
                  value={100}
                />

                <StepLabel StepIconComponent={StepIcon}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      color="text.primary"
                    >
                      {step.title}
                    </Typography>
                    {index === 1 && <LoadingPercent percent={waitingTime} />}
                  </Box>
                </StepLabel>
                <StepContent>
                  <Typography variant="body2" color="text.secondary">
                    {step.detail}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </StepperStyle>

          <Box
            mb={10}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            borderTop={1}
            borderColor="divider"
            py={3}
          >
            {getStringButton() != null && (
              <Button
                disabled={disableStep || !connected}
                onClick={onPressButton}
                variant="contained"
                color="primary"
                className={activeStep === 2 ? "effect-btn" : ""}
              >
                {getStringButton()}
              </Button>
            )}
            <Typography
              variant="caption"
              color="warning.main"
              pl={2}
              textAlign="right"
            >
              {getStringDescriptionBtn()}
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
};

export default RegisterTab;
