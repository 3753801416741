import { Box, styled, Typography } from "@mui/material";
import React from "react";
import useStatus from "../hooks/useStatus";
import useStatusColor from "../hooks/useStatusColor";

const BoxStyle = styled(Box)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Dot = styled("div")`
  border: 2px solid white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
`;

const Status = ({ status, ...props }) => {
  const color = useStatusColor(status);
  const i18nStatus = useStatus(status);

  return (
    <BoxStyle {...props}>
      {color !== "" && (
        <Dot
          style={{
            background: color,
          }}
        />
      )}
      <Typography
        variant="body2"
        color="text.secondary"
        textTransform="capitalize"
      >
        {i18nStatus}
      </Typography>
    </BoxStyle>
  );
};

export default Status;
