import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";
import { useSearchContext } from "../store";
import ConnectButton from "./ConnectButton";
import LanguageButton from "./LanguageButton";

const Logo = (props) => {
  return (
    <Box flexShrink={0} {...props}>
      <NavLink to="/">
        <img src={logo} alt="" height={36} />
      </NavLink>
    </Box>
  );
};

const NavLinkButton = styled(NavLink)`
  transition: 0.1s;
`;

const Header = () => {
  const { t } = useTranslation();
  const { connected } = useSearchContext();
  const pages = useMemo(() => {
    return [
      {
        name: t("myAccount"),
        path: "/account",
        visible: connected,
      },
      {
        name: t("faq"),
        path: "/faq",
        visible: true,
      },
    ];
  }, [connected, t]);

  const { palette } = useTheme();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{ minHeight: "initial !important", py: 1.5 }}
        >
          <Logo
            sx={{
              mr: 2,
            }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) =>
              page.visible ? (
                <Box key={page.name} px={2}>
                  <NavLinkButton
                    to={page.path}
                    style={({ isActive }) => {
                      return isActive
                        ? { color: palette.primary.main }
                        : undefined;
                    }}
                  >
                    {page.name}
                  </NavLinkButton>
                </Box>
              ) : (
                ""
              )
            )}
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              ml: "auto",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              elevation={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem sx={{ background: "transparent !important" }}>
                <ConnectButton />
              </MenuItem>
              <MenuItem sx={{ background: "transparent !important" }}>
                <LanguageButton
                  sx={{
                    width: "100%",
                    background: "#F4F4F4",
                    borderRadius: "8px",
                  }}
                />
              </MenuItem>
              {pages.map((page) =>
                page.visible ? (
                  <MenuItem
                    key={page.name}
                    sx={{
                      py: 0,
                      alignItems: "stretch",
                      minHeight: "initial",
                      "> a": { width: "100%", lineHeight: "40px" },
                    }}
                  >
                    <NavLinkButton
                      to={page.path}
                      style={({ isActive }) => {
                        return isActive
                          ? { color: palette.primary.main }
                          : undefined;
                      }}
                      onClick={handleCloseNavMenu}
                    >
                      {page.name}
                    </NavLinkButton>
                  </MenuItem>
                ) : (
                  ""
                )
              )}
            </Menu>
          </Box>

          <Box
            sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
            alignItems="center"
          >
            <LanguageButton sx={{ mr: 2 }} />
            <ConnectButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
