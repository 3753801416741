import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const useStatusColor = (status) => {
  const { palette } = useTheme();
  const [color, setColor] = useState();

  useEffect(() => {
    if (status === "registrant") {
      setColor(palette.primary.main);
    } else if (status === "available") {
      setColor(palette.success.main);
    } else if (status === "unavailable") {
      setColor(palette.text.secondary);
    } else {
      setColor("");
    }
  }, [status]);

  return color;
};

export default useStatusColor;
