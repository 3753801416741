import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useControllerClient } from "../hooks/useControllerClient";
import connect from "../images/connect.png";
import dropdown from "../images/dropdown.png";
import wallet from "../images/wallet.png";
import { useSearchContext } from "../store";
import { initChain, getAccount } from "../utils/MetamaskHelper";

const ButtonStyle = styled(Box)`
  background: linear-gradient(214.11deg, #c7ebf6 41.53%, #f8f9c7 129.19%);
  background-image: url(${connect});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 200px;

  button {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding: 0 16px;
    background: linear-gradient(324.51deg, #6c5d9e -0.49%, #3db3f7 75.74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bold;

    .MuiButton-startIcon {
      margin-right: 12px;
    }

    .MuiButton-endIcon {
      margin-left: auto;
    }
  }
`;

const ConnectButton = () => {
  const { t } = useTranslation();
  const client = useControllerClient();
  const { account, action, connected } = useSearchContext();
  const [isConnected, setIsConnected] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  useEffect(() => {
    async function get() {
      if (client) {
        // console.log({ client });
        const accountKey = await getAccount();
        // console.log({ accountKey });
        action.setAddress(accountKey);
        setIsConnected(!!accountKey);
        action.setConnected(true);
      }
    }

    get();
  }, [client, window.ethereum]);

  useEffect(() => {
    window.ethereum?.on("accountsChanged", handleAccountChange);
    return () => {
      window.ethereum?.removeListener("accountsChanged", handleAccountChange);
    };
  });

  const handleAccountChange = (...args) => {
    // you can console to see the args
    const accounts = args[0];
    // if no accounts that means we are not connected
    if (accounts.length === 0) {
      console.log("Please connect to metamask");
      // our old data is not current connected account
      // currentAccount account that you already fetched and assume you stored it in useState
    } else if (accounts[0] !== account.address) {
      // if account changed you should update the currentAccount so you return the updated the data
      // assuming you have [currentAccount,setCurrentAccount]=useState
      // however you are tracking the state currentAccount, you have to update it. in case of redux you have to dispatch update action etc
      action.setAddress(accounts[0]);
      setIsConnected(!!accounts[0]);
      action.setConnected(true);
    }
  };

  return isConnected ? (
    <>
      <ButtonStyle>
        <Button
          startIcon={<img src={wallet} alt="" height="16px" />}
          endIcon={<img src={dropdown} alt="" height="8px" />}
          onClick={handleOpenUserMenu}
        >
          {`${account.address.substring(0, 5)}...${account.address.substring(
            account.address.length - 4
          )}`}
        </Button>
      </ButtonStyle>
      <Menu
        elevation={1}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            setIsConnected(false);
            action.setConnected(false);
            handleCloseUserMenu();
          }}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <ListItemIcon sx={{ color: "inherit" }}>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{t("disconnect")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <ButtonStyle>
      <Button
        sx={{ justifyContent: "center !important" }}
        onClick={async () => {
          const connect = await initChain();
          setIsConnected(connect);
          action.setConnected(true);
        }}
      >
        {t("connectWallet")}
      </Button>
    </ButtonStyle>
  );
};

export default ConnectButton;
