import { DialogActions } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogTemplate from "./DialogTemplate";
import PeriodPriceForm from "./PeriodPriceForm";
import { ethers } from "ethers";
import { useControllerClient } from "../hooks/useControllerClient";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSearchContext } from "../store";
import { toInteger } from "lodash";
import { getAccount, getWeb3Instance, getChainId, getSIXBalance } from "../utils/MetamaskHelper";

const Web3 = require('web3')

const DialogExtend = (props) => {
  const { t } = useTranslation();
  const clientController = useControllerClient();
  const { current, action, connected } = useSearchContext();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(1);
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(year * (24 * 3600 * 365));
  const { enqueueSnackbar } = useSnackbar();


  //  call controller rentPrice(name,duration)
  // call controller renew(name,duration,[{value}])

  useEffect(() => {
    async function load() {
      if (clientController && current.name) {
        const dura = year * (24 * 3600 * 365);
        setDuration(dura);
        var price = await clientController.getPrice(current.name,dura).call();
        price = Web3.utils.fromWei(price, 'ether');
        console.log(price);
        setPrice(price);
      }
    }
    load();
  }, [clientController, year, current.name]);

  async function handleExtend() {
    setLoading(true);
    if(!connected) {
      enqueueSnackbar(t("notConnected"), {variant: "error",});
      setLoading(false);
      return
    }
    const web3Instance = await getWeb3Instance();
    const account = await getAccount();
    var domainName = Web3.utils.sha3(current.name);
    domainName = Web3.utils.toBN(domainName);
    const tx = await clientController.renew(domainName, duration);
    const gasPrice = await web3Instance.eth.getGasPrice();
    var price = await clientController.getPrice(current.name,duration).call();
    var priceEther = Web3.utils.fromWei(price, 'ether');
    const balance = await getSIXBalance();
    const checkBalance = Number(balance) <= Number(priceEther);
    if(checkBalance) {
      enqueueSnackbar(t("insufficientBalanceShort"), {variant: "error",});
      setLoading(false);
      return
    }
    console.log(process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER, account, price, duration, current.name)
    const gasLimit = await web3Instance.eth.estimateGas({
      to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
      from: account,
      value: price,
      data: tx.encodeABI()
    })
    const transactionParameters = {
      to: process.env.REACT_APP_CONTRACT_ADDRESS_CONTROLLER,
      from: account,
      value: Web3.utils.toHex(price),
      nonce: await toInteger(web3Instance.eth.getTransactionCount(account)),
      chainId: await getChainId(),
      gasPrice : Web3.utils.toHex(gasPrice),
      gasLimit: Web3.utils.toHex(gasLimit),
      data: tx.encodeABI()
    };
    window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    })
      .then((renew) => {
        const transactionReceiptAsync = function() {
          web3Instance.eth.getTransactionReceipt(renew, (error, receipt) => {
              if (error) {
                  console.log(error);
              } else if (receipt == null) {
                  setTimeout(
                      () => transactionReceiptAsync(),
                      500);
              } else {
                  console.log(receipt);
                  // console.log({ renew });
                  enqueueSnackbar(t("extendSuccess"), {
                    variant: "success",
                  });
                  action.setReloadCurrent(true);
                  setLoading(false);
                  props.onReload();
                  }
                })
              }
      transactionReceiptAsync();
      })
      .catch((err) => {
        if (!err.message.includes("rejected")) {
          enqueueSnackbar(t("extendError"), {
            variant: "error",
          });
          console.error(err);
          props.onClose();
        }
        setLoading(false);
      });
  }

  return (
    <DialogTemplate
      title={t("extend")}
      caption={t("extendCaption")}
      PaperProps={{ sx: { maxWidth: "721px" } }}
      canClose={!loading}
      {...props}
    >
      <PeriodPriceForm
        disabled={loading}
        pt={1}
        year={year}
        setYear={setYear}
        price={price}
      />
      <DialogActions sx={{ p: 0 }}>
        <LoadingButton
          color="primary"
          size="small"
          sx={{
            ml: { xs: "auto", sm: 2 },
            mt: { xs: 2, sm: 0 },
            height: "auto",
          }}
          onClick={handleExtend}
          loading={loading}
          variant="contained"
          disabled={loading}
        >
          {t("submit")}
        </LoadingButton>
      </DialogActions>
    </DialogTemplate>
  );
};

export default DialogExtend;
