import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  typography: {
    fontFamily: `"Lato", "Noto Sans Thai", sans-serif`,
  },
  palette: {
    primary: {
      main: "#6FB1FF",
    },
    text: {
      primary: "#0d0c22",
      secondary: "#6c6c6c",
      disabled: "#9E9EA7",
    },
    success: {
      main: "#69D343",
    },
    warning: {
      main: "#F5A42A",
    },
    info: {
      main: "#04B1FB",
    },
    divider: "#E7E7E9",
  },
  shadows: [
    "none",
    "0 2px 8px 0px rgb(0 0 0 / 10%) !important",
    ...Array(23).fill("none"),
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        containedPrimary: {
          color: "white",
        },
        iconSizeSmall: {
          marginRight: "4px",
        },
        sizeSmall: {
          height: "24px",
          minWidth: "80px",
        },
        sizeMedium: {
          minWidth: "96px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          width: "24px",
          height: "24px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontWeight: "bold",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: { minHeight: "16px" },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          wordBreak: "break-word",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: "16px 0",

          ".MuiFormLabel-root": {
            top: "2px",
            left: "-16px",
          },

          fieldset: { top: 0 },

          legend: {
            display: "none",
          },

          ".MuiOutlinedInput-root.Mui-disabled": {
            background: "#F4F4F4",
            color: "#0d0c22",

            input: {
              color: "#0d0c22",
              WebkitTextFillColor: "inherit",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "rgba(0,0,0,0.23)",
            },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        standardWarning: {
          background: "#ffedd3",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
