import { toInteger } from "lodash";
import Web3 from "web3";

export async function initChain() {
  const chainId = process.env.REACT_APP_CHAIN_ID;

  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      var web3Instance = new Web3(window.ethereum);
      const account = accounts[0]
      const networkId = await web3Instance.eth.net.getId();
      if (networkId !== chainId) {
        return suggestChain();
      }
      return web3Instance;
    } catch (error) {
      alert("Please allow access to MetaMask and try again");
      return null;
    }
  } else {
    alert("MetaMask is not installed. Please install the MetaMask extension and try again.");
    return null;
  }
}

export async function getAccount() {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const account = accounts[0]
  return account;
}

export async function getSIXBalance() {
  const account = await getAccount();
  const web3Instance = new Web3(window.ethereum);
  const balanceWei = await web3Instance.eth.getBalance(account);
  const balanceEther = Web3.utils.fromWei(balanceWei, 'ether');
  return balanceEther;
}

export async function getWeb3Instance() {
  if(window.ethereum) {
    const web3Instance = new Web3(window.ethereum);
    return web3Instance;
  }
  return null
}

export async function getChainId() {
  await window.ethereum.enable();
  const web3Instance = new Web3(window.ethereum);
  const networkId = await web3Instance.eth.net.getId();
  return networkId;
}

export function suggestChain() {
  return new Promise((resolve, reject) => {
    window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID) }]
    })
      .then(() => resolve(new Web3(window.ethereum)))
      .catch((err) => {
        if (err.code === 4902) {
          const customNetwork = {
            chainId: Web3.utils.toHex(process.env.REACT_APP_CHAIN_ID),
            chainName: process.env.REACT_APP_CHAIN_NAME,
            rpcUrls: [process.env.REACT_APP_RPC_URL],
            nativeCurrency: {
              name: 'SIX',
              symbol: 'SIX',
              decimals: 18,
            },
          };
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [customNetwork]
          })
            .then(() => resolve(new Web3(window.ethereum)))
            .catch((error) => reject(error));
        } else {
          console.error(err);
          reject(err);
        }
      });
  });
}