import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const LeftTimeStyle = styled("span")`
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
  padding: 0 8px;
  background: rgba(111, 177, 255, 0.15);
  color: #0d0c22;
  font-size: 0.875rem;
  display: inline-block;
  margin-right: 8px;
`;

const LeftTime = ({ years, months, days, hours, mins }) => {
  const { i18n, t } = useTranslation();

  const isExpired = years <= 0 && months <= 0 && days <= 0 && hours <= 0 && mins <= 0;
  const plural = count => {
    return count > 1 && i18n.language !== "th" ? "s" : "";
  };

  const display = isExpired
    ? t("expired")
    : years
    ? years + " " + t("year") + plural(years)
    : months
    ? months + " " + t("month") + plural(months)
    : days
    ? days + " " + t("day") + plural(days)
    : hours
    ? hours + " " + t("hour") + plural(hours)
    : mins
    ? mins + " " + t("min") + plural(mins)
    : "";
  return (
    <LeftTimeStyle>
      {i18n.language === "th" && !isExpired ? t("remain") : ""} {display}{" "}
      {i18n.language !== "th" && !isExpired ? t("remain") : ""}
    </LeftTimeStyle>
  );
};

export default LeftTime;
