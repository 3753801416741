import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

const DialogTemplate = ({
  title,
  caption,
  open,
  onClose,
  canClose = true,
  children,
  ...props
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      onClose={() => {
        if (!canClose) return;
        onClose();
      }}
      open={open}
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <DialogTitle sx={{ position: "relative" }}>
        <Typography variant="h6">{title}</Typography>
        <Typography
          variant="caption"
          display="block"
          mt={0.5}
          color="text.secondary"
        >
          {caption}
        </Typography>
        {onClose && canClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 12,
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogTemplate;
