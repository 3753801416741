import { Box, styled, Typography } from "@mui/material";
import React from "react";
import item from "../images/item.png";
import sample from "../images/sample.png";
import CopyButton from "./CopyButton";
import Status from "./Status";
import { useTranslation } from "react-i18next";

const ItemStyle = styled(Box)`
  border-radius: 8px;
  background: linear-gradient(214.11deg, #c7ebf6 41.53%, #f8f9c7 129.19%);
  background-image: url(${item});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &:hover {
    box-shadow: 0 2px 8px 0px rgb(0 0 0 / 10%);
  }

  .right {
    margin-top: 8px;
    display: flex;
    align-item: center;
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    .right {
      margin: 0;
      align-item: center;
      justify-content: center;
    }
  }
`;

const SNSItem = ({
  large = false,
  name,
  expire,
  status,
  onClick,
  onCopy,
  imageSource,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <ItemStyle
      onClick={onClick}
      p={large ? "16px 24px 16px 16px" : "8px"}
      minHeight={large ? "64px" : "initial"}
      {...props}
    >
      <img src={imageSource || sample} alt="" width={large ? "40px" : "24px"} />

      <Typography
        variant={large ? "h5" : "body1"}
        sx={{ maxWidth: "50%", mr: "auto", ml: 2 }}
        color={large ? "text.primary" : "primary"}
      >
        {name}

        {onCopy && <CopyButton sx={{ ml: 1 }} onClick={onCopy} />}
      </Typography>

      <div className="right">
        {expire && (
          <Typography color={large ? "text.secondary" : "text.disabled"}>
            {`${t("expires")} ${expire}`}
          </Typography>
        )}
        {status && (
          <Status
            status={status}
            sx={{ ml: { sm: 2 }, mt: { xs: 1, sm: 0 } }}
          />
        )}
      </div>
    </ItemStyle>
  );
};

export default SNSItem;
