import React, { useState, useEffect } from "react";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import SNSItem from "../components/SNSItem";
import { useSearchContext } from "../store";
import { ethers } from "ethers";
import moment from "moment";

const AccountDetailPage = () => {
  const navigate = useNavigate();
  const { name } = useParams();

  const { account } = useSearchContext();
  const isDetailPage = useMatch(`/account/${name}/detail`);

  useEffect(() => {
    const check =
      ((account.Rtokens || []).map((t) => t.name).includes(name) ||
        (account.Ctokens || []).map((t) => t.name).includes(name) ||
        (account.Utokens || []).map((t) => t.name).includes(name)) &&
      isDetailPage;

    console.log({
      account,
      isDetailPage: isDetailPage,
      check
    });
    if (check) {
      navigate(`/account/${name}/detail`);
    } else navigate(`/account`);
  }, [account, isDetailPage]);

  return <Outlet />;
};

export default AccountDetailPage;
