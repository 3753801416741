import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
const PeriodPriceForm = (props) => {
  const { disabled, year, setYear, price } = props;
  const { breakpoints, palette } = useTheme();
  const sm = useMediaQuery(breakpoints.up("sm"));
  const { t } = useTranslation();

  function handleAddYear() {
    const y = clamp(year + 1, 1, 99);
    setYear(y);
  }
  function handleRemoveYear() {
    const y = clamp(year - 1, 1, 99);
    setYear(y);
  }

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" {...props}>
      <TextField
        fullWidth
        disabled={disabled}
        size="small"
        label={t("period")}
        value={`${year}  ${t("year")}`}
        sx={{
          maxWidth: { sm: "200px" },
          label: { color: `${palette.text.secondary} !important` }
        }}
        InputProps={{
          disabled: true,
          sx: {
            input: { textAlign: "center" },
            background: "white !important",
            button: { color: "text.primary" }
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={handleRemoveYear}
                edge="start"
                color="inherit"
              >
                <RemoveCircleOutlineRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleAddYear} edge="end" color="inherit">
                <AddCircleRoundedIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {sm && (
        <Typography lineHeight="40px" px={2}>
          :
        </Typography>
      )}
      <TextField
        fullWidth
        sx={{
          maxWidth: { sm: "200px" },
          label: { color: `${palette.text.secondary} !important` }
        }}
        InputProps={{
          sx: {
            input: { textAlign: "center" }
          }
        }}
        size="small"
        label={t("price")}
        value={`${price} SIX`}
        disabled
      />
      {/* {sm && (
        <Typography lineHeight="40px" px={2}>
          :
        </Typography>
      )}
      <TextField
        fullWidth
        sx={{
          maxWidth: { sm: "200px" },
          label: {
            color: `${palette.text.secondary} !important`,
            textOverflow: "initial",
            overflow: "initial",
          },
        }}
        InputProps={{
          sx: {
            input: { textAlign: "center" }
          }
        }}
        size="small"
        label={t("total")}
        value="0.004 SIX"
        disabled
      /> */}
      <Typography
        variant="caption"
        color="warning.main"
        mt="-12px"
        width="100%"
      >
        {t("increasePeriod")}
      </Typography>
    </Box>
  );
};

export default PeriodPriceForm;
