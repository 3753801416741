import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import power from "../images/power.png";

const Footer = () => {
  return (
    <Box component="footer" pb={2} pt={{ xs: 5, md: 6 }}>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: { xs: "center", sm: "space-between" },
        }}
      >
        <Typography
          variant="caption"
          mb={{ xs: 1, sm: 0 }}
          color="text.secondary"
        >
          <Link
            href="https://sixprotocol.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            sx={{
              textDecoration: "none",
              color: "inherit",
              fontWeight: "bold",
            }}
          >
            Privacy Policy
          </Link>{" "}
          | © 2023 SIX Network PTE. LTD.
        </Typography>
        <img src={power} alt="" height={20} style={{ marginTop: "-8px" }} />
      </Container>
    </Box>
  );
};

export default Footer;
