import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const RemindMeButton = ({ onEmailClick, onCalendarClick, ...props }) => {
  const { t } = useTranslation();
  const [anchorElRemind, setAnchorElRemind] = useState(null);

  const handleCloseRemind = () => {
    setAnchorElRemind(null);
  };

  const handleOpenRemind = (e) => {
    setAnchorElRemind(e.currentTarget);
  };

  const handleEmailClick = () => {
    handleCloseRemind();
    onEmailClick();
  };

  const handleCalendarClick = () => {
    handleCloseRemind();
    onCalendarClick();
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={handleOpenRemind}
        startIcon={<NotificationsRoundedIcon />}
        {...props}
      >
        {t("remind")}
      </Button>
      <Menu
        elevation={1}
        anchorEl={anchorElRemind}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElRemind)}
        onClose={handleCloseRemind}
      >
        <MenuItem
          onClick={() => {
            handleEmailClick();
          }}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <ListItemIcon sx={{ color: "info" }}>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2" color="text.secondary">
              {t("Email")}
            </Typography>
          </ListItemText>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleCalendarClick();
          }}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <ListItemIcon width={0.875} sx={{ color: "info" }}>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText width={0.875} sx={{ color: "info" }}>
            <Typography variant="body2" color="text.secondary">
              {t("Google Calendar")}
            </Typography>
          </ListItemText>
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default RemindMeButton;
