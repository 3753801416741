import Web3 from "web3";
import { useState, useEffect } from "react";
const contractABI = require("../abis/Claim.json");

export function useClaimClient() {
  const [client, setClient] = useState(null);

  useEffect(() => {
    async function get() {
      if (!client) {
        if (typeof window.ethereum === "undefined") {
          alert("Please install the MetaMask extension");
          return;
        }

        var provider = new Web3.providers.HttpProvider(process.env.REACT_APP_RPC_URL);
        var web3Instance = new Web3(provider);
        try {
          const claimContract = new web3Instance.eth.Contract(
            contractABI,
            process.env.REACT_APP_EVM_ADDRESS_CLAIM
          );

          setClient(claimContract.methods);
        } catch (error) {
          console.error(error);
        }
      }
    }

    get();
    // eslint-disable-next-line
  }, [window.ethereum]);

  return client;
}
