import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { Box, Typography } from "@mui/material";
import React from "react";

const Dot = () => {
  return <FiberManualRecordRoundedIcon sx={{ fontSize: "8px", mr: 1 }} />;
};

const FAQEn = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          What does it cost to register a .SIX name service?
        </Typography>
        <Typography color="text.secondary">
          Currently, registration costs are set at the following prices:
        </Typography>
        <Typography whiteSpace="pre-line" color="text.secondary" pl={3} pt={1}>
          <Dot />3 characters .six names: 1500 six per year
          <br />
          <Dot />4 characters .six names: 300 six per year
          <br />
          <Dot />5 characters .six names 150 six per year
          <br />
          <Dot />6 - 25 characters .six names 50 six per year
          <br />
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          What can be registered as a naming scheme?
        </Typography>
        <Typography whiteSpace="pre-line" color="text.secondary" pl={3} py={1}>
          <Dot />
          Name can be 3-25 characters long.
          <br />
          <Dot />
          Names must start with a letter and end with only letters or numbers
          (letters must be in lowercase only)
          <br />
          <Dot />
          Name can consist of numbers (0-9), letters, and hyphens (-).
          <br />
        </Typography>
        <Typography color="text.secondary">
          Note: Names that contain offensive or disrespectful language are not
          permitted.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          What actions make ownership of a name invalid?
        </Typography>
        <Typography color="text.secondary">
          Buying SIX domain via Smart contract without using SIX's system
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          What occurs if I miss the renewal of my name's registration?
        </Typography>
        <Typography color="text.secondary">
          If the registration of your name has lapsed,{" "}
          <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
            you will have a 90-day grace period
          </span>{" "}
          during which you won't be able to make any changes to the records, but
          you will still have the opportunity to re-register the name. after the
          grace period and you forget to renew the registration of a name, it
          will become available for anyone to register
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          Is it possible to create my own subdomains by owning a name?
        </Typography>
        <Typography color="text.secondary">
          Yes. You can create subdomains to your liking and even assign
          ownership of them to others if you choose to do so.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          Is it possible to change the destination address that my name points
          to after it has been bought?
        </Typography>
        <Typography color="text.secondary">
          Yes, it is possible to modify the address linked to your name whenever
          you desired.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          Where can I manage all my .SIX names?
        </Typography>
        <Typography color="text.secondary">
          Go to the SIX Name Service website and click on 'My Account
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          How can I transfer domains registered in the .six registry to someone
          else?
        </Typography>
        <Typography color="text.secondary">
          To transfer a .six domain to another address, navigate to the
          registrant section of my account and click the transfer button.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight={600}>
          What is the "registrant" and "controller" of a name?
        </Typography>
        <Typography color="text.secondary">
          The owner of a .six name is referred to as the registrant. They have
          the ability to transfer ownership to another account and change the
          controller address. The registrant is also the owner of the NFT token
          that represents the name.
        </Typography>
      </Box>
    </>
  );
};

export default FAQEn;
